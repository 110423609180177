import "./App.css";
import "react-custom-scroll/dist/customScroll.css";

import { BrowserRouter } from "react-router-dom";
import { HomepageProvider, UserContext } from "./context";

import AppRouter from "./router";
import useFindUser from "./hooks/useFindUser";

function App() {
    const { user, setUser, isLoading } = useFindUser();

    return (
        <HomepageProvider>
            <UserContext.Provider value={{ user, setUser, isLoading }}>
                <BrowserRouter>
                    <AppRouter />
                </BrowserRouter>
            </UserContext.Provider>
        </HomepageProvider>
    );
}

export default App;
