import React from "react";

import { Switch } from "react-router-dom";

import MainWithFooter from "./layouts/MainWithFooter";
import PrivateRouteWithLayout from "./layouts/PrivateRouteWithLayout";

import { Home } from "./pages/Home";

const AppRouter = () => {
    return (
        <Switch>
            <PrivateRouteWithLayout
                layout={MainWithFooter}
                exact
                path="/"
                component={Home}
            />
        </Switch>
    );
};

export default AppRouter;
