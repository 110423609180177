import styles from "./contact-form-styles.module.css";
import ContactFormInput from "./ContactFormInput";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import { Response } from "../Response";
import axios from "../../utils/axios";
import React from "react";
import { Turnstile } from "@marsidev/react-turnstile";

const ContactForm = () => {
    const { user } = useContext(UserContext);

    const [data, setData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const [response, setResponse] = useState(null);
    const turnsliteRef = React.useRef();

    useEffect(() => {
        if (user) {
            setData({
                name: user.username,
                email: user.email,
            });
        }
    }, [user]);

    const handleChange = (prop) => (event) => {
        setData({ ...data, [prop]: event.target.value });
    };

    const handleContactForm = async (e) => {
        e.preventDefault();
        setResponse(null);

        try {
            const res = await axios.post("/contact", {
                name: data.name,
                email: data.email,
                subject: data.subject,
                message: data.message,
                token: turnsliteRef.current?.getResponse(),
            });

            console.log(res.data);
            setResponse("success");
        } catch (err) {
            console.log(err);
            setResponse("error");
        }
        e.target.reset();
        turnsliteRef.current?.reset();
    };

    const Error = () => {
        return (
            <Response type={"error"} setResponse={setResponse}>
                Error! Your message was not sent.
            </Response>
        );
    };

    const Success = () => {
        return (
            <Response type={"success"} setResponse={setResponse}>
                Your request was successfully submitted.
            </Response>
        );
    };

    return (
        <>
            {response === "error" && <Error />}
            {response === "success" && <Success />}
            <form className={styles.form} onSubmit={handleContactForm}>
                <h3 className={styles.title}>Send us a message</h3>

                <ContactFormInput
                    defaultValue={data.name}
                    label={"Name"}
                    onChange={handleChange("name")}
                />
                <ContactFormInput
                    defaultValue={data.email}
                    label={"Email"}
                    type={"email"}
                    onChange={handleChange("email")}
                />
                <ContactFormInput
                    label={"Subject"}
                    onChange={handleChange("subject")}
                />
                <ContactFormInput
                    label={"Message"}
                    isTextarea={true}
                    onChange={handleChange("message")}
                />

                <div style={{ marginLeft: "16px" }}>
                    <Turnstile
                        siteKey={process.env.REACT_APP_TRANSLIT_KEY}
                        options={{
                            theme: "light",
                            appearance: "interaction-only",
                        }}
                        ref={turnsliteRef}
                    />
                </div>

                <div className={styles.btnWrapper}>
                    <button
                        type={"submit"}
                        disabled={
                            !data.email ||
                            !data.subject ||
                            !data.name ||
                            !data.message
                        }
                        className={styles.btn}
                    >
                        Send message
                    </button>
                </div>
            </form>
        </>
    );
};
export default ContactForm;
