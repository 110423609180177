import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import { useContext, useEffect, useState } from "react";
import animateScrollTo from "animated-scroll-to";

import { HomepageContext } from "../context";

import CustomScroll from "react-custom-scroll";
import useDebounce from "../pages/Home/useDebounce";

const MainWithFooter = (props) => {
    const { children } = props;

    const {
        activeSection,
        setActiveSection,
        scrolling,
        setScrolling,
        prevScroll,
        setPrevScroll,
        scrollOnLoad,
        setScrollOnLoad,
    } = useContext(HomepageContext);

    const [direction, setDirection] = useState("down");

    const [scrollRef, setScrollRef] = useState();

    const [scrollY, setScrollY] = useState(0);
    const [prevented, setPrevented] = useState(false);

    const debounceScroll = useDebounce(scrollY, 250);

    // useEffect(() => {
    //     window.addEventListener("scroll", onScroll);
    //     window.addEventListener("load", onScroll);
    //
    //     return () => {
    //         window.removeEventListener("scroll", onScroll);
    //         window.removeEventListener("load", onScroll);
    //     };
    // }, [scrolling]);

    useEffect(() => {
        if (scrolling || (!activeSection && activeSection !== 0)) return;

        const sectionsList = getSectionList();

        let y = null;

        if (
            activeSection > 0 &&
            activeSection === sectionsList.length &&
            direction === "down"
        ) {
            y =
                getScrollTop() +
                sectionsList[sectionsList.length - 1].getBoundingClientRect()
                    .height;
        } else {
            if (sectionsList[activeSection]) {
                if (direction === "down") {
                    y =
                        activeSection === 0
                            ? 0
                            : sectionsList[
                                  activeSection
                              ].getBoundingClientRect().y + getScrollTop();
                } else {
                    y =
                        getScrollTop() +
                        sectionsList[activeSection].getBoundingClientRect().y;
                }
            }
        }

        if (y !== null) {
            setScrolling(true);

            setTimeout(() => {
                animateScrollTo(y, {
                    cancelOnUserAction: true,
                    elementToScroll: scrollRef?.innerContainerRef.current,
                    minDuration: scrollOnLoad ? 0 : 450,
                    speed: scrollOnLoad ? 0 : 600,
                }).then((hasScrolledToPosition) => {
                    setScrollOnLoad(false);
                    if (hasScrolledToPosition) {
                        setTimeout(() => {
                            setPrevScroll(getScrollTop());
                            setScrolling(false);
                            setPrevented(false);
                        }, 1);
                    } else {
                        setPrevScroll(getScrollTop());
                        setPrevented(true);
                        setScrolling(false);
                        setScrollY(getScrollTop());
                        // setPrevented(true, getScrollTop())
                        // handleScroll(true, getScrollTop());
                    }
                });
            }, 200);
        }
    }, [scrollOnLoad, activeSection]);

    useEffect(() => {
        handleScroll();
    }, [debounceScroll]);

    const getSectionList = () => {
        return document.querySelectorAll("section");
    };

    const getScrollTop = () => {
        // const scroll = window.scrollY
        const scroll = scrollRef
            ? scrollRef?.innerContainerRef?.current?.scrollTop
            : 0;

        return scroll;
    };

    const onScroll = (e) => {
        if (scrolling) return;

        setScrollY(getScrollTop());
    };

    const handleScroll = () => {
        const scrollTop = debounceScroll;

        const sectionsList = getSectionList();

        const diff = scrollTop - prevScroll;

        setDirection(scrollTop > prevScroll ? "down" : "up");

        if (!prevented && diff <= 20 && diff >= -20) {
            if (sectionsList.length > 0) {
                if (scrollTop > prevScroll) {
                    if (activeSection < sectionsList.length) {
                        setActiveSection(activeSection + 1);
                    }
                } else if (scrollTop < prevScroll) {
                    if (activeSection > 0) {
                        setActiveSection(activeSection - 1);
                    }
                }
            }
        } else {
            let index = 0;
            for (const element of sectionsList) {
                const viewportHeight = window.innerHeight;
                const elementOffsetTop = element.offsetTop;
                const elementHeight = element.offsetHeight;

                // Calculate percentage of the element that's been seen
                const distance = scrollTop + viewportHeight - elementOffsetTop;
                const percentage = Math.round(
                    distance / ((viewportHeight + elementHeight) / 100)
                );
                const percent = Math.min(100, Math.max(0, percentage));

                if (percent >= 50 && percent < 100) {
                    let scrollto = index;

                    if (
                        index === activeSection &&
                        index < sectionsList.length
                    ) {
                        scrollto += 1;
                    } else if (index === activeSection && index > 0) {
                        index -= 1;
                    }

                    setActiveSection(scrollto);
                }

                index++;
            }
        }

        setPrevScroll(scrollTop);
    };

    return (
        <CustomScroll
            ref={(scrollbar) => {
                setScrollRef(scrollbar);
            }}
            onScroll={onScroll}
            heightRelativeToParent={"100vh"}
        >
            <Header />

            <main>{children}</main>

            <Footer />
        </CustomScroll>
    );
};

export default MainWithFooter;
