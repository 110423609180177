import styles from "./footer-styles.module.css";
import lb from "../../../images/lb.png";

const Footer = () => {
    return (
        <footer
            className={styles.footer}
            style={{ backgroundImage: `url(${lb})` }}
        >
            <div className={styles.container}>
                <div>
                    <h5 className={styles.title}>Contact us directly</h5>

                    <p>White Thesis</p>

                    <p>
                        Tuggen 8856
                        <br />
                        Switzerland
                        <br />
                        Email:{" "}
                        <a href="mailto:contact@whitethesis.com">
                            contact@whitethesis.com
                        </a>
                    </p>
                </div>

                <img src={"/logo-bg.svg"} alt={"White Thesis"} />
            </div>
        </footer>
    );
};

export default Footer;
