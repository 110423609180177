// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
    z-index: 99;
}
.rcs-custom-scroll .rcs-inner-handle {
    background: var(--primary-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,2CAA2C;AAC/C","sourcesContent":[".rcs-custom-scroll .rcs-outer-container .rcs-positioning {\n    z-index: 99;\n}\n.rcs-custom-scroll .rcs-inner-handle {\n    background: var(--primary-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
