import styles from "./home-styles.module.css";
import lb from "../../images/lb.png";

import { ContactForm } from "../../shared/ContactForm";
import { useContext, useEffect, useState } from "react";
import { HomepageContext } from "../../context";

const ScrollArrow = () => {
    const { scrolling, activeSection, setActiveSection } =
        useContext(HomepageContext);
    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(scrolling || (activeSection || 0) === 3);
    }, [scrolling, activeSection]);

    const handleClick = () => {
        setActive(true);

        setActiveSection(Math.min((activeSection || 0) + 1, 3));
    };

    return (
        <div className={`${styles.split}`}>
            <button
                onClick={handleClick}
                className={`${styles.scrollButton} ${
                    active ? styles.active : ""
                }`}
            >
                <span className={styles.arrowCircle}>
                    <span className={styles.arrowCircleHalf}>
                        <span className={styles.arrowCircleHalfInnerWrapper}>
                            <span className={styles.arrowCircleHalfInner} />
                        </span>
                    </span>
                    <span className={styles.arrowCircleHalf}>
                        <span className={styles.arrowCircleHalfInnerWrapper}>
                            <span className={styles.arrowCircleHalfInner} />
                        </span>
                    </span>

                    <span className={styles.arrowCircleHalf}>
                        <span className={styles.arrowCircleHalfInnerWrapper}>
                            <span className={styles.arrowCircleHalfInner} />
                        </span>
                    </span>
                </span>
                <span className={styles.arrow}>
                    <span className={styles.arrowBody}></span>
                    <span className={styles.arrowBranch}></span>
                    <span className={styles.arrowBranch}></span>
                </span>
            </button>
        </div>
    );
};

const Home = () => {
    const Hero = () => {
        const { setActiveSection } = useContext(HomepageContext);

        return (
            <section
                className={styles.hero}
                style={{ backgroundImage: `url(${lb})` }}
                id={"home"}
            >
                <div className={styles.heroInner}>
                    <div className={styles.heroInnerInfo}>
                        <h1 className={styles.heroTitle}>
                            We are an AI-powered legal knowledge hub: it is your
                            shortcut to the{" "}
                            <span className={styles.hightlight}>
                                investment
                            </span>{" "}
                            and{" "}
                            <span className={styles.hightlightSecondary}>
                                tax legislations
                            </span>
                        </h1>
                        <h5>Get your</h5>
                        <a
                            href="/"
                            className={styles.heroFeatures}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveSection(1);
                            }}
                        >
                            <span>simple and structured tax reviews</span>
                            <img
                                src={"/images/arrow-icon.svg"}
                                alt="arrow-icon"
                            />
                        </a>
                        <a
                            href="/"
                            className={styles.heroFeatures}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveSection(2);
                            }}
                        >
                            <span>get best-fit legal structures</span>
                            <img
                                src={"/images/arrow-icon.svg"}
                                alt="arrow-icon"
                            />
                        </a>
                    </div>
                    <img src={"/images/dataviz-picture.png"} alt={""} />
                </div>
            </section>
        );
    };

    const DealTaxes = () => {
        const { setActiveSection } = useContext(HomepageContext);

        return (
            <section className={styles.section} id={"deal-taxes"}>
                <div className={styles.heroInner}>
                    <div className={styles.sectionInnerInfo}>
                        <h2>
                            Based on your inputs, our platform provides
                            intuitive and condensed{" "}
                            <span className={styles.hightlightOrange}>
                                tax assessments
                            </span>{" "}
                            and{" "}
                            <span className={styles.hightlightOrange}>
                                optimisation routes
                            </span>
                        </h2>
                        <div className={styles.heroFeatures}>
                            <span>Path to tax-aware investing</span>
                        </div>

                        <a
                            className={styles.link}
                            href={"/"}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveSection(3);
                            }}
                        >
                            Check how it works
                        </a>
                    </div>
                    <img
                        style={{ paddingBottom: 50 }}
                        src={"/images/structures_taxes.png"}
                        alt={""}
                    />
                </div>
            </section>
        );
    };

    const LegalStructures = () => {
        const { setActiveSection } = useContext(HomepageContext);

        return (
            <section className={styles.section} id={"legal-structures"}>
                <div className={styles.heroInner}>
                    <div className={styles.sectionInnerInfo}>
                        <h2>
                            We{" "}
                            <span className={styles.hightlightBlue}>
                                unravel the intricacies of laws and regulations,
                            </span>{" "}
                            their implications, hidden interconnections,
                            especially in cross-border situations
                        </h2>
                        <div className={styles.heroFeatures}>
                            <span>Instantly get lean reviews</span>
                        </div>

                        <a
                            className={styles.link}
                            href={"/"}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveSection(3);
                            }}
                        >
                            Check how it works
                        </a>
                    </div>
                    <img
                        style={{ paddingBottom: 50 }}
                        src={"/images/structure-on.png"}
                        alt={""}
                    />
                </div>
            </section>
        );
    };

    const Contact = () => {
        return (
            <section
                className={`${styles.section} ${styles.contactSection}`}
                id={"contact"}
            >
                <ContactForm />
            </section>
        );
    };

    return (
        <>
            <Hero />
            <DealTaxes />
            <LegalStructures />
            <Contact />

            <ScrollArrow scrollTo={3} />
        </>
    );
};

export default Home;
