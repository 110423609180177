import styles from "./aside-styles.module.css";
import { useContext } from "react";
import { HomepageContext } from "../../../context";

import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

const Aside = ({ active, onStateChange }) => {
    const { setActiveSection } = useContext(HomepageContext);

    const scrollToSection = (e) => {
        e.preventDefault();

        const target = e.target.getAttribute("href");

        if (!target || target === "#home") {
            setActiveSection(0);
        } else {
            const el = document.querySelector(target);

            const index = [...el.parentElement.childNodes].indexOf(el);

            setActiveSection(index);
        }
    };

    return (
        <>
            <button
                className={`${styles.hamburger} ${active ? styles.active : ""}`}
                onClick={onStateChange}
            >
                <span />
            </button>

            <CSSTransition
                in={active}
                timeout={400}
                classNames={{
                    appear: styles.fadeAnimation,
                    appearActive: styles.fadeAnimationActive,
                    appearDone: styles.fadeAnimationActive,
                    enter: styles.fadeAnimation,
                    enterActive: styles.fadeAnimationActive,
                    enterDone: styles.fadeAnimationActive,
                    exit: styles.fadeAnimation,
                    exitActive: styles.fadeAnimation,
                    exitDone: styles.fadeAnimation,
                }}
                unmountOnExit
            >
                <div className={`${styles.wrapper}`} onClick={onStateChange}>
                    <aside className={styles.aside}>
                        <a href={"#home"} onClick={scrollToSection}>
                            <img src={"/logo-white.svg"} alt={"White Thesis"} />
                        </a>

                        <nav>
                            <a href={"#deal-taxes"} onClick={scrollToSection}>
                                Deal taxes
                            </a>
                            <a
                                href={"#legal-structures"}
                                onClick={scrollToSection}
                            >
                                Legal structures
                            </a>
                            <a href={"#contact"} onClick={scrollToSection}>
                                Other requests
                            </a>
                            <a href={"#contact"} onClick={scrollToSection}>
                                Contact us
                            </a>

                            <a
                                href={process.env.REACT_APP_CLIENT_URL}
                                className={` ${styles.account}`}
                            >
                                Your account
                            </a>
                        </nav>
                    </aside>
                </div>
            </CSSTransition>
        </>
    );
};

export default Aside;
