// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.response-styles_response__PcpEy {
    border: 1px solid #707070;
    background: #fff;
    padding: 36px 12px;

    display: flex;
    align-items: center;
    font-size: 20px;
    width: 485px;
    cursor: pointer;
}

.response-styles_fixed__nQ0n8 {
    position: fixed;
    z-index: 96;
    top: 34px;
    right: 18px;
}

.response-styles_aligned__\\+sRjv {
    position: relative;
    margin: auto;
}

.response-styles_response__PcpEy span {
    display: block;
    margin: auto 0 auto 16px;
}

.response-styles_error__hXbMH {
    color: var(--alert-color);
}

.response-styles_success__igSqs {
    color: var(--success-color);
}

.response-styles_response__PcpEy button {
    background: transparent;
    position: absolute;
    top: 12px;
    right: 13px;
    border: 0;
    padding: 0;
    height: 14px;
    width: 14px;
}

.response-styles_response__PcpEy button:before,
.response-styles_response__PcpEy button:after {
    content: "";
    display: block;
    width: 14px;
    height: 1px;
    background: #707070;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 0;
    margin: auto;
}

.response-styles_response__PcpEy button:before {
    transform: rotate(45deg);
}

.response-styles_response__PcpEy button:after {
    transform: rotate(-45deg);
}

@media (max-width: 1680px) {
    .response-styles_response__PcpEy {
        font-size: 18px;
        width: 457px;
        padding: 29px 12px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/shared/Response/response-styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,SAAS;IACT,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;AACf;;AAEA;;IAEI,WAAW;IACX,cAAc;IACd,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,wBAAwB;IACxB,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,eAAe;QACf,YAAY;QACZ,kBAAkB;IACtB;AACJ","sourcesContent":[".response {\n    border: 1px solid #707070;\n    background: #fff;\n    padding: 36px 12px;\n\n    display: flex;\n    align-items: center;\n    font-size: 20px;\n    width: 485px;\n    cursor: pointer;\n}\n\n.fixed {\n    position: fixed;\n    z-index: 96;\n    top: 34px;\n    right: 18px;\n}\n\n.aligned {\n    position: relative;\n    margin: auto;\n}\n\n.response span {\n    display: block;\n    margin: auto 0 auto 16px;\n}\n\n.error {\n    color: var(--alert-color);\n}\n\n.success {\n    color: var(--success-color);\n}\n\n.response button {\n    background: transparent;\n    position: absolute;\n    top: 12px;\n    right: 13px;\n    border: 0;\n    padding: 0;\n    height: 14px;\n    width: 14px;\n}\n\n.response button:before,\n.response button:after {\n    content: \"\";\n    display: block;\n    width: 14px;\n    height: 1px;\n    background: #707070;\n    transform-origin: center;\n    position: absolute;\n    top: 50%;\n    left: 0;\n    margin: auto;\n}\n\n.response button:before {\n    transform: rotate(45deg);\n}\n\n.response button:after {\n    transform: rotate(-45deg);\n}\n\n@media (max-width: 1680px) {\n    .response {\n        font-size: 18px;\n        width: 457px;\n        padding: 29px 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"response": `response-styles_response__PcpEy`,
	"fixed": `response-styles_fixed__nQ0n8`,
	"aligned": `response-styles_aligned__+sRjv`,
	"error": `response-styles_error__hXbMH`,
	"success": `response-styles_success__igSqs`
};
export default ___CSS_LOADER_EXPORT___;
