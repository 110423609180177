import styles from "./contact-form-styles.module.css";
import { useEffect, useState } from "react";

const ContactFormInput = ({
    defaultValue,
    isTextarea,
    label,
    type,
    onChange,
}) => {
    const [active, setActive] = useState(false);
    const [value, setValue] = useState("");

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
            setActive(true);
        }
    }, [defaultValue]);

    const handleFocus = () => {
        setActive(true);
    };

    const handleBlur = () => {
        if (value === "") {
            setActive(false);
        }
    };

    const handleChange = (e) => {
        setValue(e.target.value);
        onChange(e);
    };

    return (
        <label className={`${styles.label} ${active ? styles.focused : ""}`}>
            <span className={styles.placeholder}>{label}</span>
            {isTextarea ? (
                <textarea
                    className={styles.input}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
            ) : (
                <input
                    type={type || "text"}
                    value={defaultValue}
                    className={styles.input}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
            )}
        </label>
    );
};

export default ContactFormInput;
