import styles from "./header-styles.module.css";
import Aside from "../Aside/Aside";
import { useState, useEffect, useContext } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { HomepageContext } from "../../../context";

const Header = () => {
    let location = useLocation();
    const history = useHistory();

    const { activeSection, setActiveSection, setScrollOnLoad } =
        useContext(HomepageContext);

    const [active, setActive] = useState(false);

    useEffect(() => {
        if (location.search) {
            const page = new URLSearchParams(location.search).get("page");

            let active = null;
            if (page === "contact-us") {
                active = 3;
            } else if (page === "legal-structures") {
                active = 2;
            } else if (page === "deal-taxes") {
                active = 1;
            }

            if (active) {
                setScrollOnLoad(true);
                setActiveSection(active);

                const queryParams = new URLSearchParams(location.search);
                if (queryParams.has("page")) {
                    queryParams.delete("page");
                    history.replace({
                        search: queryParams.toString(),
                    });
                }
            }
        }

        setActive(false);
    }, [location]);

    const handleMenuState = () => {
        setActive(!active);
    };

    const scrollToSection = (e) => {
        e.preventDefault();

        const target = e.target.getAttribute("href");

        console.log(target);

        if (!target || target === "#home") {
            setActiveSection(0);
        } else {
            const el = document.querySelector(target);

            const index = [...el.parentElement.childNodes].indexOf(el);

            setActiveSection(index);
        }
    };

    return (
        <>
            <header className={styles.header} id={"header"}>
                <div className={styles.wrapper}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>
                            <a href={"#home"} onClick={scrollToSection}>
                                <img src={"/logo.svg"} alt="arrow-icon" />
                            </a>
                        </div>
                    </div>

                    <div className={styles.nav}>
                        <a
                            className={`${styles.navItem} ${
                                activeSection === 1 ? styles.active : ""
                            }`}
                            href={"#deal-taxes"}
                            onClick={scrollToSection}
                        >
                            Deal taxes
                        </a>
                        <a
                            className={`${styles.navItem} ${
                                activeSection === 2 ? styles.active : ""
                            }`}
                            href={"#legal-structures"}
                            onClick={scrollToSection}
                        >
                            Legal structures
                        </a>
                        <a
                            className={`${styles.navItem} ${
                                activeSection >= 3 ? styles.active : ""
                            }`}
                            href={"#contact"}
                            onClick={scrollToSection}
                        >
                            Contact us
                        </a>
                    </div>
                    <div className={styles.login}>
                        <a href={`${process.env.REACT_APP_CLIENT_URL}login`}>
                            <span className={styles.icon}>
                                <svg
                                    id="Icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                >
                                    <rect
                                        id="Area"
                                        width="20"
                                        height="20"
                                        fill="#fcfcfc"
                                        opacity="0"
                                    />
                                    <g
                                        id="Icon-2"
                                        data-name="Icon"
                                        transform="translate(3.333 2.5)"
                                    >
                                        <path
                                            id="Path"
                                            d="M16.667,17.5V15.833A3.334,3.334,0,0,0,13.333,12.5H6.667a3.334,3.334,0,0,0-3.334,3.333V17.5"
                                            transform="translate(-3.333 -2.5)"
                                            fill="none"
                                            stroke="#000"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                        <circle
                                            id="Path-2"
                                            data-name="Path"
                                            cx="3.333"
                                            cy="3.333"
                                            r="3.333"
                                            transform="translate(3.334)"
                                            fill="none"
                                            stroke="#000"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>

                                <svg
                                    className={styles.iconActive}
                                    id="Icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                >
                                    <rect
                                        id="Area"
                                        width="20"
                                        height="20"
                                        fill="#064591"
                                        opacity="0"
                                    />
                                    <g
                                        id="Icon-2"
                                        data-name="Icon"
                                        transform="translate(1.29 2.499)"
                                    >
                                        <path
                                            id="Path"
                                            d="M16.667,17.5V15.833A3.334,3.334,0,0,0,13.333,12.5H6.667a3.334,3.334,0,0,0-3.334,3.333V17.5Z"
                                            transform="translate(-1.29 -2.499)"
                                            fill="#1b53b1"
                                            stroke="#0c3b8d"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                        <circle
                                            id="Path-2"
                                            data-name="Path"
                                            cx="3.333"
                                            cy="3.333"
                                            r="3.333"
                                            transform="translate(5.377 0.001)"
                                            fill="#1b53b1"
                                            stroke="#0c3b8d"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </header>

            <Aside active={active} onStateChange={handleMenuState} />
        </>
    );
};

export default Header;
