import { useEffect, useState } from "react";
import axios from "../utils/axios";
import jwtDecode from "jwt-decode";

const useFindUser = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const findUser = async () => {
            setIsLoading(false);
            return;
            // try {
            //     const res = await axios.get("/auth/refresh-token");
            //
            //     localStorage.setItem("t", res.data);
            //     setUser(jwtDecode(res.data));
            //     setIsLoading(false);
            // } catch (e) {
            //     console.log(e);
            //     setIsLoading(false);
            // }
        };

        findUser();
    }, []);

    return {
        user,
        setUser,
        isLoading,
    };
};

export default useFindUser;
