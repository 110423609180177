import React, { useContext } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { UserContext } from "../context";

const PrivateRouteWithLayout = (props) => {
    const { layout: Layout, title, component: Component, ...rest } = props;

    const { user, isLoading } = useContext(UserContext);

    if (isLoading) {
        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <Layout title={title}>
                        <Component {...matchProps} />
                    </Layout>
                )}
            />
        );
    } else {
        if (user) {
            return (
                <Route
                    exact
                    path="/"
                    render={() =>
                        (window.location = process.env.REACT_APP_CLIENT_URL)
                    }
                />
            );
        }

        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <Layout title={title}>
                        <Component {...matchProps} />
                    </Layout>
                )}
            />
        );
    }
};

PrivateRouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default PrivateRouteWithLayout;
