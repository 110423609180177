import { createContext, useState } from "react";

export const HomepageContext = createContext({
    activeSection: null,
    sections: [],
    scrolling: false,
    prevScroll: 0,
    scrollOnLoad: false,
    setActiveSection: () => {},
    setSections: () => {},
    setScrolling: () => {},
    setPrevScroll: () => {},
    setScrollOnLoad: () => {},
});

export const HomepageProvider = ({ children }) => {
    const [activeSection, setActiveSection] = useState(null);
    const [sections, setSections] = useState([]);
    const [scrolling, setScrolling] = useState(false);
    const [prevScroll, setPrevScroll] = useState(0);
    const [scrollOnLoad, setScrollOnLoad] = useState(false);

    return (
        <HomepageContext.Provider
            value={{
                activeSection,
                setActiveSection,
                sections,
                setSections,
                scrolling,
                setScrolling,
                prevScroll,
                setPrevScroll,
                scrollOnLoad,
                setScrollOnLoad,
            }}
        >
            {children}
        </HomepageContext.Provider>
    );
};
